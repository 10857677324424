import React, { useState } from "react"
import PropTypes from "prop-types"
import { useSpring, animated } from "react-spring"
import { Location, useLocation } from "@reach/router"
import Modal from "@/util/Modal"
import InfoModal from "@/components/infoModal"
import { useMedia } from "react-use"
import Header from "./header"
import useBodyScrollLock from "@/util/useBodyScrollLock"
// import { ActiveProject } from "@/state/containers"
import Link from "@/components/ani-link"
import { useLocomotiveScrollPosition } from "@/util/locomotiveScroll/hooks"
import cn from "classnames"
import * as style from "./index.modules.scss"

const HeaderWithScrollComponent = React.memo(
  ({ phrases, categories, countHomeProjects, info }) => {
    const [lockScroll, scrollLockRef] = useBodyScrollLock()
    const location = useLocation()

    const [headerOpen, setHeaderOpen] = useState(false)
    const [isMobMenuOpen, setMobMenuOpen] = useState(false)
    const [infoOpen, setInfoOpen] = useState(false)

    const [mobMenuProps, setMobMenuProps] = useSpring(() => ({
      transform: "translateY(-100%)",
      config: { mass: 0.9, tension: 190, friction: 26 },
    }))

    useLocomotiveScrollPosition(
      ({ currPos }) => {
        const isVisible = currPos.y > 200

        setHeaderOpen(isVisible)
      },
      [headerOpen]
    )

    React.useEffect(() => {
      setMobMenuProps({
        transform: isMobMenuOpen ? "translateY(0)" : "translateY(-100%)",
      })
      lockScroll(isMobMenuOpen)

      if (isMobMenuOpen) {
        setHeaderOpen(isMobMenuOpen)
      }
    }, [isMobMenuOpen])

    const headerProps = {
      phrases,
      categories,
      countHomeProjects,
      info,
      onOpenInfo: () => setInfoOpen(true),
      onClickHamburger: () => setMobMenuOpen(!isMobMenuOpen),
      isMobMenuOpen: isMobMenuOpen,
    }

    const isHomePage = location.pathname === "/"

    return (
      <>
        <div
          className={cn(
            isHomePage ? style.headerFixedHomePage : style.headerFixed,
            isHomePage && headerOpen && style.headerFixedHomePageScrolled
          )}
        >
          <Header variant={headerOpen ? "fixed" : undefined} {...headerProps}>
            <HeaderContent countHomeProjects={countHomeProjects} />
          </Header>
        </div>

        <animated.div
          className={cn(style.mobMenu, "flex items-center justify-center")}
          style={mobMenuProps}
        >
          <nav className={"flex flex-col text-center"} ref={scrollLockRef}>
            <div className={"text-x6s"}>work:</div>
            {categories.map((x, i) => (
              <Link
                to={`/projects/?category=${x.slug}`}
                key={x.slug}
                className={cn("text-x8 lowercase mt-2")}
                onClick={() => setMobMenuOpen(false)}
              >
                {`${x.title}${i === categories.length - 1 ? "" : ","}`}
              </Link>
            ))}
            <div
              className={"text-x6s mt-3"}
              onClick={() => {
                setInfoOpen(true)
                setMobMenuOpen(false)
              }}
            >
              info
            </div>
          </nav>
        </animated.div>

        <Modal isOpen={infoOpen} onClose={() => setInfoOpen(false)}>
          <InfoModal {...info} onClose={() => setInfoOpen(false)} />
        </Modal>
      </>
    )
  }
)

const HeaderContent = ({ countHomeProjects }) => {
  // const activeProject = ActiveProject.useContainer()
  const isWide = useMedia("(min-width: 1024px)")

  return (
    <Location>
      {({ location }) => {
        if (isWide && location.pathname === "/" && countHomeProjects > 0) {
          return (
            <div className={"text-x2"}>
              Selected works{" "}
              <span className={style.inCircle}>{countHomeProjects}</span>
            </div>
          )
        }

        // if (!isWide && /^\/projects\/?$/.test(location.pathname)) {
        //   return <div>Work</div>
        // }

        // if (/\/[\w\d-]+/.test(location.pathname) && activeProject.project) {
        //   return <div className={"text-x2"}>{activeProject.project.title}</div>
        // }

        return null
      }}
    </Location>
  )
}

HeaderWithScrollComponent.propTypes = {
  siteTitle: PropTypes.string,
  countHomeProjects: PropTypes.number,
  phrases: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
  categories: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, slug: PropTypes.string })
  ),
  info: PropTypes.shape({
    header: PropTypes.string,
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    links: PropTypes.arrayOf(PropTypes.string),
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
  }),
}

export default HeaderWithScrollComponent
