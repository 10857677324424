import React, {useState} from 'react';
import {createContainer} from 'unstated-next'

function useActiveProject(initialState = {}) {
  let [project, set] = useState(initialState)
  return { project, set }
}

export const ActiveProject = createContainer(useActiveProject)

export const StateWrapper = ({children}) => (
  <ActiveProject.Provider>
    {children}
  </ActiveProject.Provider>
)
