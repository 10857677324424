import React from "react"
import PropTypes from "prop-types"
import Img from "@/lib/gatsby-image"
import cn from "classnames"
import CrossFullIcon from "@/assets/icons/cross-full.inline.svg"
import CvIcon from "@/assets/icons/cv.inline.svg"
import IgIcon from "@/assets/icons/ig.inline.svg"
import LnIcon from "@/assets/icons/ln.inline.svg"
import * as style from "./index.modules.scss"

const ICONS = {
  cv: <CvIcon />,
  instagram: <IgIcon />,
  linkedin: <LnIcon />,
}

const MDIconLink = ({ to, ...rest }) => {
  // eslint-disable-next-line
  const [_, text, link] = /^(\w+)\[(.+)\]$/.exec(to) || []

  if (!text || !link) {
    return null
  }

  return (
    <a
      href={link}
      target={"_blank"}
      rel={"nofollow noopener noreferrer"}
      {...rest}
    >
      <span className="flex items-center justify-center">
        {ICONS[text.toLowerCase()] ?? text}
      </span>
    </a>
  )
}

MDIconLink.propTypes = {
  to: PropTypes.string,
}

export default function InfoModal({ header, image, links, text, onClose }) {
  return (
    <aside className={cn(style.root, "relative text-center pt-6 pb-18 px-5")}>
      <button
        className={cn(style.closeButton, "absolute top-0 right-0 py-6 px-6")}
        onClick={onClose}
      >
        <CrossFullIcon />
      </button>

      <header className={"text-x2 desktop:text-x1s"}>{header}</header>
      {text?.text && (
        <div className={"uppercase text-x2p desktop:text-x1 pt-3"}>
          {text.text}
        </div>
      )}

      <Img {...image} className={"block mt-7 mb-10 mx-auto"} />

      {links.map((x) => (
        <MDIconLink
          key={x}
          to={x}
          className={"block text-x9s desktop:text-x0 hover:text-white mb-4"}
        />
      ))}
    </aside>
  )
}

InfoModal.propTypes = {
  header: PropTypes.string,
  text: PropTypes.object,
  image: PropTypes.object,
  links: PropTypes.arrayOf(PropTypes.string),
}
