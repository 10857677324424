import type { LScroll } from "./types"

class InstanceStore<T> {
  private instance: T | null = null
  private listeners: (() => void)[] = []

  set(val: T | null) {
    this.instance = val

    for (const listener of this.listeners) {
      listener()
    }
  }

  clear() {
    this.set(null)
  }

  get(): T | null {
    return this.instance
  }

  on(listener: () => void) {
    this.listeners.push(listener)
  }

  off(listener: () => void) {
    this.listeners.forEach((lstnr, i) => {
      if (lstnr === listener) {
        this.listeners.splice(i, 1)
      }
    })
  }
}

const store = new InstanceStore<LScroll>()

export default store
