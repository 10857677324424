import React from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { Transition, animated } from "react-spring/renderprops"
import cn from "classnames"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import * as style from "./modal.modules.scss"

class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isClient: false,
    }
  }

  componentDidMount() {
    this.el = document.createElement("div")
    document.body.appendChild(this.el)

    this.setState({ isClient: true })
  }

  componentDidUpdate(prevProps) {
    setTimeout(() => {
      if (this.props.isOpen && !prevProps.isOpen) {
        disableBodyScroll(this.$innerRef, { reserveScrollBarGap: true })
      }

      if (!this.props.isOpen && prevProps.isOpen) {
        enableBodyScroll(this.$innerRef)
      }
    })
  }

  componentWillUnmount() {
    document.body.removeChild(this.el)
  }

  render() {
    const { isOpen, onClose, children } = this.props

    if (!this.state.isClient) {
      return null
    }

    return ReactDOM.createPortal(
      <>
        {isOpen && <div className={style.modalBackdrop} onClick={onClose} />}
        <Transition
          native
          items={isOpen}
          from={{ transform: "translateX(100%)" }}
          enter={{ transform: "translateX(0)" }}
          leave={{ transform: "translateX(100%)" }}
        >
          {(show) =>
            show &&
            ((props) => (
              <animated.div
                className={cn(style.modalWrapper, { "is-open": isOpen })}
                style={props}
              >
                <div
                  ref={(el) => (this.$innerRef = el)}
                  className={"w-full h-full overflow-auto relative z-10"}
                >
                  {children}
                </div>
              </animated.div>
            ))
          }
        </Transition>
      </>,
      this.el
    )
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default Modal
