import React, { useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { useLocation } from "@reach/router"
import ClientOnly from "@/util/ClientOnly"
import LocomotiveScroll from "@/util/locomotiveScroll/lazy"
import Loadable from "@loadable/component"
import { useMedia } from "react-use"
import "@/assets/scss/global.scss"

const DrawCanvasLazy = Loadable(() => import("@/components/draw-canvas"))

const Layout = ({ children }) => {
  const {
    site,
    allContentfulCategory,
    allContentfulProjectsList,
    allContentfulInfo,
  } = useStaticQuery(graphql`
    query LayoutDataQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulCategory(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            title
            slug
          }
        }
      }
      allContentfulProjectsList(
        filter: {
          slug: { eq: "home-page" }
          projects: { elemMatch: { node_locale: { eq: "en-US" } } }
        }
      ) {
        edges {
          node {
            projects {
              slug
            }
          }
        }
      }
      allContentfulInfo(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            header
            image {
              fixed(width: 200, quality: 70) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
            }
            links
            text {
              text
            }
          }
        }
      }
    }
  `)

  const isDesktop = useMedia("(min-width: 1024px)")
  const headerProps = useMemo(() => {
    return {
      siteTitle: site.siteMetadata?.title,
      categories: (allContentfulCategory?.edges || []).map((x) => x.node),
      countHomeProjects:
        allContentfulProjectsList?.edges?.[0]?.node?.projects?.length ?? 0,

      info: allContentfulInfo?.edges?.[0]?.node,
    }
  }, [site, allContentfulCategory, allContentfulInfo])

  const location = useLocation()

  return (
    <>
      <Header {...headerProps} />
      <ClientOnly>
        {isDesktop && (
          <DrawCanvasLazy className="fixed top-0 left-0 w-full h-full pointer-events-none z-50" />
        )}
      </ClientOnly>
      <LocomotiveScroll locationKey={location.pathname}>
        {({ ref }) => (
          <main data-scroll-container ref={ref}>
            <ContentWrapper>{children}</ContentWrapper>
          </main>
        )}
      </LocomotiveScroll>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const ContentWrapper = ({ children }) => {
  const wrapperRef = useRef()

  useEffect(() => {
    if (wrapperRef.current) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          wrapperRef.current.style.opacity = 1
        })
      }, 1000)
    }
  }, [])

  return (
    <div
      data-scroll-section
      ref={wrapperRef}
      className="transition-opacity duration-500"
      style={{ opacity: 0, transitionDelay: 500 }}
    >
      {children}
    </div>
  )
}

export default Layout
