import React, {
  useState,
  ReactNode,
  useEffect,
  Fragment,
  createElement,
  FC,
} from "react"

const ClientOnly: FC<{
  placeholder?: ReactNode
}> = ({ children, placeholder }) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return createElement(Fragment, { children: placeholder }) || null
  }

  return createElement(Fragment, { children })
}

export default ClientOnly
