const React = require("react")

const { StateWrapper } = require("@/state/containers")

exports.wrapPageElement = ({ element, props }) => (
  <StateWrapper>{element}</StateWrapper>
)

exports.shouldUpdateScroll = () => {
  return false
}
