import { useState, useEffect, useRef } from "react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

export default function useBodyScrollLock() {
  const [lock, setLock] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const current = ref.current

    if (lock) {
      disableBodyScroll(current, { reserveScrollBarGap: true })
    } else {
      enableBodyScroll(current)
    }

    return function cleanup() {
      return enableBodyScroll(current)
    }
  }, [lock])

  return [setLock, ref]
}
