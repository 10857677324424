import React from "react"
import PropTypes from "prop-types"
import HamburgerIcon from "@/assets/icons/hamburger.inline.svg"
import CrossIcon from "@/assets/icons/cross.inline.svg"
import cn from "classnames"
import * as style from "./hamburgerButton.modules.scss"

const HamburgerIconStatic = React.memo(
  () => <HamburgerIcon />,
  () => true
)

const HamburgerButton = ({ isOpen, onClick }) => {
  return (
    <button onClick={onClick} className={cn(style.root, { "is-open": isOpen })}>
      <HamburgerIconStatic />

      <span className={style.crossIcon}>
        <CrossIcon />
      </span>

      <div className={style.clearOverlay}>
        <div onClick={onClick} className={style.clearOverlayStroke}></div>
        <div onClick={onClick} className={style.clearOverlayStroke}></div>
      </div>
    </button>
  )
}

HamburgerButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
}

export default HamburgerButton
