import React from "react"
import type { GatsbyLinkProps } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap from "gsap"
import store from "@/util/locomotiveScroll/store"

const fade = ({ exit: { length }, node, direction }) => {
  const duration = length
  const opacity = direction === "in" ? 1 : 0

  return gsap
    .timeline()
    .call(() => {
      if (direction === "in") {
        store.get()?.scrollTo("top", { duration: 0.01, disableLerp: true })
      }
    })
    .fromTo(
      node,
      { opacity: opacity === 1 ? 0 : 1 },
      {
        opacity: opacity,
        duration,
        delay: direction === "in" ? 1 : 0,
        onComplete() {},
      }
    )
}

type AniLinkProps = GatsbyLinkProps<{}>
const AniLink = (props: AniLinkProps) => {
  return (
    <TransitionLink
      exit={{
        length: 0.3,
        // zIndex: 1,
        trigger: ({ exit, node }) => fade({ exit, node, direction: "out" }),
      }}
      entry={{
        length: 0.3,
        delay: 0.3,
        trigger: ({ exit, node }) => fade({ exit, node, direction: "in" }),
      }}
      {...props}
    />
  )
}

export default AniLink
