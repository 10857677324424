import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import Link from "@/components/ani-link"
import { Location } from "@reach/router"
import { useSpring, animated } from "react-spring"
import LogoIcon from "@/assets/icons/logo.inline.svg"
import { useMeasure } from "react-use"
import cn from "classnames"
import HamburgerButton from "./hamburgerButton"
import * as style from "./header.modules.scss"

const WorksAnimatedList = React.memo(({ categories, location, isOpen }) => {
  const [refMeasure, { width, height }] = useMeasure()
  const [worksWidthSpring, setWorksSpring] = useSpring(() => ({ width: 0 }))
  const urlParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])

  React.useEffect(() => {
    setWorksSpring({ width: isOpen ? width : 0 })
  }, [isOpen, width])

  return (
    <animated.div
      style={{
        width: worksWidthSpring.width,
        height,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        ref={refMeasure}
        style={{ position: "absolute", display: "flex" }}
        className={"text-x1 leading-none flex items-center"}
      >
        <div>:&nbsp;</div>
        <Link
          to={`/projects/`}
          className={cn(style.categoryLink, "lowercase")}
          style={{ opacity: urlParams.get("category") ? 0.2 : 1 }}
        >
          all,&nbsp;
        </Link>

        {categories.map((x, i) => (
          <Link
            to={`/projects/?category=${x.slug}`}
            key={x.slug}
            className={cn(style.categoryLink, "lowercase")}
            style={{
              opacity: urlParams.get("category") === x.slug ? 1 : 0.2,
            }}
            dangerouslySetInnerHTML={{
              __html: `${x.title}${
                i !== categories.length - 1 ? ",&nbsp;" : ""
              }`,
            }}
          />
        ))}
      </div>
    </animated.div>
  )
})

const Works = ({ isOpen, categories, location }) => {
  const [isMounted, setMounted] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMounted(true)
    }, 500)

    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [])

  return (
    <div className={"flex items-center"}>
      <Link to={"/projects/"} className={"text-x1s leading-none"}>
        work
      </Link>

      {isMounted && (
        <WorksAnimatedList
          categories={categories}
          location={location}
          isOpen={isOpen}
        />
      )}
    </div>
  )
}

Works.propTypes = {
  isOpen: PropTypes.bool,
  categories: PropTypes.array,
}

const CenterText = ({ isVisible, children }) => {
  const [props, set] = useSpring(() => ({
    position: "relative",
    transform: "translate3d(0px,0,0)",
    opacity: 0,
  }))

  React.useEffect(() => {
    set(
      isVisible
        ? { transform: "translate3d(0px,0,0)", opacity: 1 }
        : { transform: "translate3d(-30px,0,0)", opacity: 0 }
    )
  }, [isVisible])

  return <animated.div style={props}>{children}</animated.div>
}

CenterText.propTypes = {
  isVisible: PropTypes.bool,
}

const Logo = ({ show }) => {
  return show ? (
    <Link to={"/"} className={style.logoWrapper}>
      <LogoIcon />
    </Link>
  ) : (
    <div></div>
  )
}

Logo.propTypes = {
  show: PropTypes.bool,
}

export default function Header({
  categories,
  variant,
  onOpenInfo,
  children,
  onClickHamburger,
  isMobMenuOpen = false,
}) {
  const isFixed = variant === "fixed"
  const toggleMobMenu = onClickHamburger

  return (
    <Location>
      {({ location }) => {
        return (
          <header
            className={cn(
              style.root,
              "relative flex items-center justify-between text-x1s px-3 desktop:px-7",
              { [style.rootNotFixed]: !isFixed },
              { [style.rootFixed]: isFixed }
            )}
          >
            <Logo show={isFixed || location.pathname !== "/"} />

            {isFixed && (
              <CenterText isVisible={location.pathname === "/"}>
                {children}
              </CenterText>
            )}

            <div className={"hidden desktop:flex items-center"}>
              <Works
                isOpen={/^\/projects\/?$/.test(location.pathname)}
                categories={categories}
                location={location}
              />
              <button
                className={"text-x1s pl-5 leading-none"}
                onClick={onOpenInfo}
              >
                info
              </button>
            </div>

            <div className={"block desktop:hidden relative z-10"}>
              <HamburgerButton isOpen={isMobMenuOpen} onClick={toggleMobMenu} />
            </div>
          </header>
        )
      }}
    </Location>
  )
}

Header.propTypes = {
  variant: PropTypes.oneOf(["fixed"]),
  onOpenInfo: PropTypes.func,
  onClickHamburger: PropTypes.func,
  immediate: PropTypes.bool,
  isMobMenuOpen: PropTypes.bool,
}
