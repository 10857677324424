import React from "react"
import loadable from "@loadable/component"

const LocomotiveScrollLazy = loadable(() => import("./index"))
function LocomotiveScrollClient({ children, locationKey }) {
  return typeof window === "undefined" ? (
    <div>{children}</div>
  ) : (
    <LocomotiveScrollLazy locationKey={locationKey}>
      {children}
    </LocomotiveScrollLazy>
  )
}

export default LocomotiveScrollClient
