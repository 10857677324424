import React, { useEffect, useRef, useState, useCallback } from "react"
import type { LScroll, ScrollPos } from "./types"
import store from "./store"

export function useLocomotiveScroll() {
  const [instance, setInstance] = useState<LScroll | null>(null)

  const handleStoreUpdate = useCallback(() => {
    setInstance(store.get())
  }, [])

  useEffect(() => {
    store.on(handleStoreUpdate)

    return () => {
      store.off(handleStoreUpdate)
    }
  }, [])

  return instance
}

type ScrollHandlerProps = {
  prevPos: ScrollPos
  currPos: ScrollPos
}

export function useLocomotiveScrollPosition(
  handler: (args: ScrollHandlerProps) => void,
  handlerArgs: any[] = []
) {
  const instance = useLocomotiveScroll()
  const prevPosRef = useRef<ScrollPos>({ y: 0 })

  const handleScroll = useCallback((args) => {
    const { limit, scroll, delta } = args
    const currPos: ScrollPos = { y: scroll.y }

    handler({ currPos, prevPos: prevPosRef.current })
    prevPosRef.current = currPos
  }, handlerArgs)

  useEffect(() => {
    if (instance) {
      instance.on("scroll", handleScroll)
    }
  }, [instance])
}
